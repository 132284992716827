<template>
  <div class="invitation">
    <div class="is-centered">
      <img src="../../assets/logo.png" style="height: 100px" alt />
    </div>
    <section class="section">
      <div class="columns">
        <div class="column">
          <h1 class="subtitle">Congratulations!</h1>
          <p>About London Elegance Club small animation</p>
          <img src="../../assets/shoes.jpg" style="height: 400px" alt />
          <p>Intro:</p>
          <hooper>
            <slide>slide 1</slide>
            <slide>slide 2</slide>
            <slide>slide 3</slide>
            <slide>slide 4</slide>
            <slide>slide 5</slide>
            <slide>slide 6</slide>

            <hooper-pagination slot="hooper-addons"></hooper-pagination>
          </hooper>
        </div>
      </div>
      <hr />
      <div class="columns is-mobile">
        <div class="column">
          <router-link to="/members/main">
            <b-button type="is-primary">Continue</b-button>
          </router-link>
        </div>
      </div>
      <!-- <b-field label="Message">
            <b-input maxlength="200" type="textarea"></b-input>
      </b-field>-->
    </section>
  </div>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  name: 'InvitationCompleted',
  components: {
    Hooper,
    Slide,
    HooperPagination
  }
}
</script>

<style lang="scss" scoped>
.section {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
  max-width: 500px;
  margin: auto;
  margin-top: 0px;
}
.hooper-slide {
  background: red;
}
</style>
