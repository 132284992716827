<template>
  <div
    class="registration is-vcentered"
    style="overflow-y: scroll; height: 72vh; margin-top: -0px"
  >
    <section class="section">
      <div class="smallhero mb-4">
        <h1 class="title is-size-5" style="color: black">Registration</h1>
      </div>
      <div style="line-height: 24px; margin-bottom: 15px; text-align: left">
        <p style="font-size: 0.85rem !important">
          Unlike most big social websites, we do care a lot about privacy.<br /><br />
          We don't sell/share any private information. Only your chosen
          <i>Username</i> is going to be displayed on the website.
          <!-- Our content is
          ad-free 🥳, however 3rd party content (YouTube, website links) may
          contain it. -->
          <br /><br />In case you decide to leave a comment or participate in
          our Discussions, we expect politeness.
        </p>
      </div>
      <b-field label="First Name (Initial is ok too)">
        <b-input
          v-model="first_name"
          placeholder="First Name"
          maxlength="40"
        ></b-input>
      </b-field>
      <b-field label="Last Name (Initial is ok too)">
        <b-input
          v-model="last_name"
          placeholder="Last Name"
          maxlength="40"
        ></b-input>
      </b-field>
      <b-field label="Display Name (username)">
        <b-input
          v-model="username"
          placeholder="Username"
          type="text"
          required
          maxlength="40"
          minlength="5"
        ></b-input>
      </b-field>

      <b-field label="Email">
        <b-input
          v-model="email"
          required
          type="email"
          placeholder="Enter your email"
          maxlength="40"
        ></b-input>
      </b-field>
      <b-field label="Password">
        <b-input
          v-model="password"
          placeholder="Password"
          type="password"
          password-reveal
          required
          validation-message="Must have at least 1 uppercase letter, lowercase letter, at least 1 number or special character & min 7 long"
          pattern="^(?=.*[\d\W])(?=.*[a-z])(?=.*[A-Z]).{7,100}$"
          minlength="7"
        ></b-input>
      </b-field>
      <b-field label="Password">
        <b-input
          v-model="confirm_password"
          placeholder="Confirm Password"
          type="password"
          password-reveal
          required
          minlength="7"
          validation-message="Must have at least 1 uppercase letter, lowercase letter, at least 1 number or special character & min 7 long"
          pattern="^(?=.*[\d\W])(?=.*[a-z])(?=.*[A-Z]).{7,100}$"
        ></b-input>
      </b-field>
      <p style="margin-bottom: 10px">
        <small class="is-size-7"
          >By proceeding you agree with our basic
          <a href="/terms.html" target="_blank">Terms & Conditions</a></small
        >
      </p>
      <div class="is-centered mt-4 mb-2">
        <b-button
          type="is-primary is-light"
          icon-left="a far fa-smile noIconMarginTop"
          @click="submit"
          >Start the Journey</b-button
        >
      </div>
      <!-- <b-steps>
        <b-step-item label="Account" icon="account-key">
          This is a test
        </b-step-item>
        <b-step-item label="Profile" icon="account">
          This is a second test
        </b-step-item>
        <b-step-item label="Social" icon="account-plus">
          This is a third test
        </b-step-item>
      </b-steps>-->
    </section>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import ApiService from '@/services/ApiService'
export default Vue.extend({
  data() {
    return {
      first_name: '',
      last_name: '',
      username: '',
      email: '',
      password: '',
      confirm_password: '',
      agreeWithTerms: false,
      showTermsModal: false,
    }
  },
  mounted() {},
  methods: {
    submit() {
      if (!this.username || !this.email || !this.password) {
        this.$toast({
          message: `Please fill the missing fields`,
          type: 'is-danger',
        })
      } else if (this.password != this.confirm_password) {
        this.$toast({
          message: `Make sure typed passwords are the same`,
          type: 'is-danger',
        })
      } else {
        const invitation_code = this.$store.state.general.invitation_code
        let obj = {
          first_name: this.first_name,
          last_name: this.last_name,
          username: this.username,
          email: this.email,
          password: this.password,
          code: invitation_code,
        }
        ApiService.registration
          .register(obj)
          .then((resp) => {
            this.$store.dispatch('setToken', resp.data.token)
            this.$router.push({ name: 'onboarding' })
            console.log(resp.data)
          })
          .catch((e) => {
            this.$notify({ type: 'danger', message: e.data.message })
          })
      }
    },
  },
})
</script>
<style lang="scss" scoped>
div.registration {
  box-shadow: 0px 0px 15px rgb(0, 0, 0, 0.08);
}
.section {
  /* background-color: #fff4e8; */
  background-image: repeating-linear-gradient(
    45deg,
    #fff6f1 0,
    #ffebe0 3px,
    transparent 0,
    transparent 50%
  );
  background-size: 10px 10px;
  background-color: #fff4e8;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
  max-width: 600px;
  margin: auto;
  margin-top: 0px;
}
</style>
