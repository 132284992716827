<template>
  <div
    class="invitation"
    style="min-height: 100vh; height: 100vh; overflow: hidden"
  >
    <div class="invitationValid" style="height: 100%" v-if="invitationIsValid">
      <div class="mainPage">
        <div class="is-centered" style="position: relative">
          <img src="@/assets/logo_old.png" style="height: 100px" alt />
        </div>
        <div style="min-height: 72vh">
          <div
            v-if="!showRegistrationForm"
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              height: 100%;
            "
          >
            <transition name="slide-in">
              <p
                v-if="!hideIntroText"
                class="textShadow quoteText"
                :class="{ active: introMessageAnimation }"
              >
                <strong
                  >Your single-use membership invitation code is valid!</strong
                ><br /><br />
                <i class="fas fa-quote-left"></i>
                There are more important things than just a beautiful pair of
                shoes or a dress. This one of a kind project isn't just about
                that. It's about growth, good habits, positive change, a bit of
                style and much more.<br /><br />
                We don’t know what your exact formula for better, more
                fulfilling life is, but we're sure, this project will help you
                find out.
                <span v-if="invitationType !== 'paid'"
                  >And will do so without requiring any payment from you -
                  <b>it's a gift from us</b>.</span
                >
                <i class="fas fa-quote-right"></i>
                <span class="lec">- London Elegance Club</span>
              </p>
            </transition>
            <transition name="slide-in">
              <div v-if="!hideIntroText">
                <b-button type="is-info is-light" @click="showRegistration"
                  ><i class="fas fa-check"></i> Go to Registration</b-button
                >
              </div>
            </transition>
          </div>
          <transition name="slide-in">
            <Registration
              v-if="showRegistrationForm"
              :class="{ mobile: isMobile }"
            />
          </transition>
        </div>
      </div>
      <!-- <img id="sneakersImage" src="@/assets/sneakers.png" />
      <img id="diaryImage" src="@/assets/diary.png" />
      <img id="walletImage" src="@/assets/wallet.png" />
      <img id="foodImage" src="@/assets/food.png" />
      <img id="hatImage" src="@/assets/hat.png" />
      <img id="shoesImage" src="@/assets/shoes.png" />
      <img id="dumbellImage" src="@/assets/dumbell.png" />
      <img id="bagImage" src="@/assets/bag.png" />
      <img id="plantImage" src="@/assets/plant.png" /> -->
      <!-- <img
        id="handImage"
        src="@/assets/handButton.png"
        @click="showRegistration"
      />
      <div style="clear: both"></div> -->
    </div>
    <transition name="slide-in">
      <div
        v-if="invitationIsValid === false"
        key="check"
        style="display: flex;flex-direction: column;min-height: 100vh;justify-content: center;
}"
      >
        <div class="is-centered" style="position: relative">
          <img src="@/assets/logo_old.png" style="height: 120px" alt />
        </div>
        <div class="hero">
          <h1 class="title is-size-4" style="color: black">
            <vue-typer
              :text="typingArray"
              :repeat="0"
              type-delay="30"
            ></vue-typer>
          </h1>
        </div>
      </div>
    </transition>
    <div style="clear: both"></div>
  </div>
</template>

<script>
import anime from 'animejs'
import { VueTyper } from 'vue-typer'
import Vue from 'vue'
import Registration from '@/views/onboarding/Registration'
import ApiService from '@/services/ApiService'
export default Vue.extend({
  components: {
    Registration,
    VueTyper,
  },
  data() {
    return {
      invitationIsValid: undefined,
      showInvitationCheck: true,
      showIntro: false,
      showRegistrationForm: false,
      code: '',
      invitationType: null,
      typingArray: [],
      introMessageAnimation: false,
      screenWidth: 0,
      screenHeight: 0,
      isMobile: window.innerWidth < 650 ? true : false,
      hideIntroText: false,
    }
  },
  mounted() {
    const code = this.$route.params.code
    const code2 = this.$route.params.code2
    const fullCode = code + '/' + code2
    this.screenHeight = window.innerHeight
    this.screenWidth = window.innerWidth
    // this.checkInvitationLink(fullCode)

    this.code = fullCode
    ApiService.registration
      .checkInvitation(fullCode)
      .then((resp) => {
        console.log(resp.data)
        this.typingArray = ['Checking your invitation code']
        this.invitationType = resp.data.type
        this.invitationIsValid = true
        this.showInvitationCheck = false
      })
      .catch((e) => {
        const message = e.data.message

        if (message === 'This invitation has already been used') {
          this.typingArray = [
            'This invitation has already been used on ' + e.data.date,
          ]
          this.invitationIsValid = false
        } else if (message === "Wrong invitation or it's expired.") {
          this.typingArray = [
            'Wrong invitation or it\'s expired.',
          ]
          // this.$forceUpdate();
          this.invitationIsValid = false
        }

      })
  },

  methods: {
    showRegistration() {
      // save invitaiton code
      this.$store.dispatch('setInvitationCode', this.code)
      // this.introMessageAnimation = false
      this.hideIntroText = true
      // const handElement = document.querySelector('#handImage')
      anime({
        targets: '#handImage',
        easing: 'easeOutSine',
        duration: 500,
        bottom: -400,
        scale: [0.7, 1.3],
        rotate: [0, 20],
      })

      setTimeout(() => {
        this.showRegistrationForm = true
      }, 600)
      // this.$router.push('/registration')
    },
    checkInvitationLink() {
      return true
    },
  },
})
</script>
<style lang="scss" scoped >
.vue-typer {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 18px;
}
div.invitation {
  // background: url('~@/assets/spark.jpg') no-repeat center bottom;
  // background: url('~@/assets/mobile-view.jpg') no-repeat center bottom;
  // background-color: black;
  // background-size: cover;
  background: rgb(255, 225, 192);
  background: linear-gradient(
    180deg,
    rgba(255, 225, 192, 1) 0%,
    rgba(255, 209, 158, 1) 100%
  );
  margin-left: calc(-50vw + 50%);
  min-width: 100vw;
  // height: 100%;
  // overflow: hidden;
  width: 100vw;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 9;
  display: block;
  max-width: 100% !important;
  // margin: 0 !important;
}
.lec {
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: 12px;
  font-style: italic;
}
.textShadow {
  // color: white;
  color: rgb(48, 48, 48);
  text-align: justify;
  background-color: rgba(255, 219, 173, 1);
  border-radius: 5px;
  line-height: 1.5em !important;
  box-shadow: 0px 0px 15px rgb(0, 0, 0, 0.08);
  /* box-shadow: 0px 0px 3px #848484; */
  background-color: rgba(255, 255, 255, 0.7);
}
.bgsmall {
  background: rgba(255, 255, 255, 0.7);
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  display: inline-block;
}
.typed {
  font-weight: 400;
}
div.mainPage {
  display: flex;
  flex-direction: column;
  /* justify-items: ; */
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
}
.quoteText {
  position: relative;
  max-width: 600px;
  /* margin: auto; */
  padding: 45px;
  line-height: 18px;
  font-size: 15px;
  .fa-quote-left {
    position: absolute;
    left: 18px;
    top: 75px;
  }
  .fa-quote-right {
    position: absolute;
    // left: 10px;
    // top: 30px;
    bottom: 30px;
    padding-left: 10px;
  }
}
@media only screen and (min-width: 600px) {
  #app {
    background: url('~@/assets/desktop-view.jpg') no-repeat center bottom;
    background-color: black;
    background-size: cover;
  }
}
h1.typing {
  justify-content: center;
  // text-shadow: 1px 1px 10px #fff, 1px 1px 10px #ccc;
}
</style>
<style scoped>
.mobile {
  margin-top: -80px;
}
.section {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  color: #d8d8d8;
  display: block;
  padding: 1.25rem;
  max-width: 500px;
  margin: auto;
  margin-top: 20px;
}
#foodImage,
#diaryImage,
#walletImage {
  position: absolute;
  top: -500px;
  left: 0px;
  height: 30vh;
  max-height: 500px;
}
#walletImage {
  height: 20vh;
  max-height: 180px;
}
#shoesImage,
#plantImage,
#bagImage {
  position: absolute;
  top: 0px;
  left: -450px;
  height: 28vh;
  max-height: 500px;
}
#shoesImage {
  left: -700px;
}
#hatImage2,
#dumbellImage,
#sneakersImage {
  position: absolute;
  top: 0px;
  right: -540px;
  height: 28vh;
  max-height: 500px;
}
#handImage {
  position: absolute;
  bottom: -600px;
  max-width: 70vh;
  left: 300px;
  cursor: pointer;
  animation: animatedBackground 8s linear infinite;
}
@keyframes animatedBackground {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(-4px) rotate(1deg) scale(0.98);
  }
  50% {
    transform: translateX(1px) rotate(0deg);
  }
  65% {
    transform: translateX(3px) rotate(1deg) scale(1.02);
  }
  80% {
    transform: translateX(0px) rotate(0deg);
  }
  89% {
    transform: translateX(-3px) rotate(1.1deg);
  }
  100% {
    transform: translateX(0) rotate(0deg);
  }
}
</style>